<template>
  <el-dialog title="重置密码" :visible.sync="repasswordVisible" :close-on-click-modal="false" width="600px" append-to-body :before-close="handleClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
      <el-form-item label="新密码" prop="password">
        <el-input class="w230" placeholder="请输入新密码" v-model="ruleForm.password"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" prop="confirmNewPassword">
        <el-input class="w230" placeholder="请确认新密码" v-model="ruleForm.confirmNewPassword"></el-input>
      </el-form-item>
      <div class="info">
        密码可输入6-16位之间的数字或字母（字母区分大小写）。
        在设置时，尽量不要出现连续数字的情况。
      </div>
    </el-form>
    <div slot="footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import { resetPasswordsUnit } from "@/api/unitManage"
  export default {
    name: "repasswordDilog",
    props: {
      repasswordVisible: {
        type: Boolean,
        default: false
      },
      repasswordParams: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      var validatePass = (rule, value, callback) => {
        const pwpattent = /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,16}$/;
        if (pwpattent.test(value)) {
          callback();
        } else {
          callback(
            new Error("密码必须是8-16位大小写英文字母、数字、字符组合。")
          );
        }

      };
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.ruleForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      return {
        ruleForm: {
          confirmNewPassword: '',
          password: '',
        },
        rules: {
          password: [{ required: true, validator: validatePass, trigger: 'blur' }],
          confirmNewPassword: [{ required: true, validator: validatePass2, trigger: 'blur' }],
        }
      }
    },
    methods: {
      handleClose() {
        this.$emit('update:repasswordVisible', false)
      },
      onConfirm() {
        this.resetPasswordsUnitPost()
      },
      async resetPasswordsUnitPost() {
        let params = JSON.parse(JSON.stringify(this.repasswordParams))
        params.password = this.ruleForm.password
        const res = await resetPasswordsUnit(params)
        if(res.state === 0) {
          this.$message.success(res.data)
          this.$emit('update:repasswordVisible', false)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .w230 {
    width: 300px;
  }
  .info {
    margin-left: 130px;
    width: 300px;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    color: #666666;
  }
</style>
